import { useUserContext } from '@/contexts/user-context';
import { useRouter } from '@/hooks/useRouter';
import { Flex, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarLink from './SidebarLink';
import SidebarSettings from './SidebarSettings';
import StoreSwitcher from './StoreSwitcher';

import LogoNeatDark from '@/assets/logo-neat-dark.svg';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import api from '@/core/client';
import { Section } from '@/utils/access/user-access';
import { useQuery } from '@tanstack/react-query';
import {
    HiChartBar,
    HiCog,
    HiDocumentAdd,
    HiDocumentDuplicate,
    HiOutlineChartBar,
    HiOutlineCog,
    HiOutlineDocumentAdd,
    HiOutlineDocumentDuplicate,
    HiOutlineSparkles,
    HiOutlineSupport,
    HiOutlineUserGroup,
    HiSparkles,
    HiSupport,
    HiUserGroup,
    HiX,
} from 'react-icons/hi';

const SidebarNavigation = ({ onClose }: { onClose?: () => void }) => {
    const { t } = useTranslation(['common', 'admin', 'claims', 'store']);
    const { activeStore, stores, hasAccessTo } = useUserContext();
    const { onlyLocatedIn, isLocatedIn } = useRouter();
    const { data: activeSalesChannels } = useQuery({
        queryKey: ['store', activeStore?.id ?? '', 'sales-channels', 'ignoreDisabled', true],
        queryFn: () => api.stores.getStoreSalesChannels(activeStore!.id, { ignoreDisabled: true }),
        enabled: !!activeStore?.id,
    });
    const { isAdmin } = useUserContext();
    const filteredSalesChannels = isAdmin
        ? activeSalesChannels
        : activeSalesChannels?.filter((salesChannel) => salesChannel.isDisplayedInApp);
    const logoUrl = activeStore?.settings?.logoUrl;

    const navigationItems = useMemo(
        () =>
            [
                {
                    component: (
                        <SidebarLink
                            key={'menu_dashboard'}
                            href="/dashboard"
                            label={t('common:dashboard')}
                            icon={HiOutlineChartBar}
                            activeIcon={HiChartBar}
                            onClick={onClose}
                        />
                    ),
                    section: Section.DASHBOARD,
                },
                {
                    component: activeStore && (
                        <SidebarLink
                            key={'menu_clients'}
                            href={`/stores/${activeStore?.id}/customers`}
                            label={t('common:insured')}
                            icon={HiOutlineUserGroup}
                            activeIcon={HiUserGroup}
                            active={onlyLocatedIn(`/stores/${activeStore?.id}/customers`)}
                            onClick={onClose}
                        />
                    ),
                    section: Section.CLIENTS,
                },
                {
                    component: activeStore && (
                        <SidebarLink
                            key={'menu_subscriptions'}
                            href={`/stores/${activeStore?.id}/subscriptions`}
                            label={t('common:subscriptions')}
                            icon={HiOutlineDocumentDuplicate}
                            activeIcon={HiDocumentDuplicate}
                            active={onlyLocatedIn(`/stores/${activeStore?.id}/subscriptions`)}
                            onClick={onClose}
                        />
                    ),
                    section: Section.SUBSCRIPTIONS,
                },
                {
                    component: activeStore && !!filteredSalesChannels?.length && (
                        <SidebarLink
                            key={'menu_subscribe'}
                            href={`/stores/${activeStore?.id}/sales-channels/${filteredSalesChannels[0]?.id}/contracts`}
                            label={t('common:subscribe')}
                            activeIcon={HiDocumentAdd}
                            icon={HiOutlineDocumentAdd}
                            onClick={onClose}
                            active={isLocatedIn(`/stores/${activeStore?.id}/sales-channels`)}
                        />
                    ),
                    section: Section.SUBSCRIBE,
                    specificCondition: !!(activeStore && activeStore?.salesChannels.length),
                },
            ]
                .filter(({ section, specificCondition }) => hasAccessTo(section, specificCondition))
                .map(({ component }) => component),

        [t, onClose, activeStore, isLocatedIn, onlyLocatedIn, hasAccessTo, filteredSalesChannels],
    );

    return (
        <Flex flexDirection="column" height="100%" alignItems="center" gap={3} py={2}>
            {onClose && (
                <IconButtonV2
                    IconComponent={HiX}
                    label={t('common:close')}
                    variant="ghost"
                    size="lg"
                    fontSize="xl"
                    onClick={onClose}
                />
            )}
            <motion.div
                initial={false}
                animate={{
                    opacity: !logoUrl ? 1 : 0,
                    height: !logoUrl ? 'auto' : 0,
                }}
            >
                <LogoNeatDark width="50" style={{ marginTop: 8 }} />
            </motion.div>
            {stores.length > 0 && <StoreSwitcher />}
            <VStack spacing={3} overflowY="auto" overflowX="hidden">
                {navigationItems}
                {activeStore && (
                    <>
                        {hasAccessTo(Section.STORE_CLAIMS) && (
                            <SidebarLink
                                href={`/stores/${activeStore.id}/claims`}
                                label={t('store:claims.alias')}
                                icon={HiOutlineSupport}
                                active={isLocatedIn(`/stores/${activeStore.id}/claims`)}
                                activeIcon={HiSupport}
                                onClick={onClose}
                            />
                        )}
                        {hasAccessTo(Section.COMPANY) && (
                            <SidebarLink
                                href={`/store-settings/${activeStore?.id}`}
                                label={t('common:settings')}
                                icon={HiOutlineCog}
                                activeIcon={HiCog}
                                onClick={onClose}
                            />
                        )}

                        {hasAccessTo(Section.CLAIMS) && (
                            <SidebarLink
                                href={`/claims/list`}
                                label={t('claims:title')}
                                icon={HiOutlineSupport}
                                active={isLocatedIn(`/claims`)}
                                activeIcon={HiSupport}
                                onClick={onClose}
                            />
                        )}
                        {hasAccessTo(Section.ADMIN) && (
                            <SidebarLink
                                href={`/admin/search`}
                                label={t('admin:administration.title')}
                                icon={HiOutlineSparkles}
                                active={isLocatedIn(`/admin`)}
                                activeIcon={HiSparkles}
                                onClick={onClose}
                            />
                        )}
                    </>
                )}
            </VStack>

            <Flex flexGrow={1} />

            <SidebarSettings />
        </Flex>
    );
};

export default SidebarNavigation;
