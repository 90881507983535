import type { AvatarProps } from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/react';
import React from 'react';

export type UserAvatarProps = {
    name: string;
    href?: string;
} & AvatarProps;

const colors = [
    'blue',
    'purple',
    'secondary',
    'primary',
    'red',
    'orange',
    'yellow',
    'beige',
    'linkedin',
    'twitter',
    'whatsapp',
    'cyan',
    'gray',
];
const UserAvatar = React.forwardRef(function UserAvatar(
    { name, size = 'sm', ...props }: UserAvatarProps,
    ref: any,
) {
    const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');
    const colorIndex = (initials.charCodeAt(0) || 0) + (initials.charCodeAt(1) || 0);
    const colorScheme = colors[colorIndex % colors.length];
    return (
        <Avatar
            color={`${colorScheme}.600`}
            bg={`${colorScheme}.100`}
            size={size}
            name={name}
            ref={ref}
            {...props}
        />
    );
});

export default UserAvatar;
