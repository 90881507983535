import api from '@/core/client';
import type {
    DealerDto,
    DealerRole,
    ErrorsDto,
    StoreDto,
    UpdateDealerDto,
} from '@/generated/typing';
import { ActorType, QueryOrder } from '@/generated/typing';
import { createGenericContext } from '@/utils/create-generic-context';
import type { Session } from '@/types/next-auth';
import { useSession } from 'next-auth/react';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useStores } from '@/hooks/useStores';
import { dealerHasAccessTo } from '@/utils/access/dealer-access';
import type { Feature, Section } from '@/utils/access/user-access';
import type { AxiosError } from 'axios';
import useHandleError from '@/hooks/useHandleError';
import { useTranslation } from 'react-i18next';
import useToast from '@/hooks/useToast';

type DealerContextType = {
    refetchStores: () => void;
    isDealerActor: boolean;
    loading: boolean;
    activeStore?: StoreDto;
    dealer?: DealerDto;
    stores: StoreDto[];
    setActiveStoreId: (storeId: string) => void;
    selectDealerStore: (storeId: string) => void;
    dealerRole?: DealerRole;
    hasDealerAccessTo: (item: Feature | Section, specificCondition?: boolean) => boolean;
    updateDealer: (updateDealerDto: UpdateDealerDto) => void;
};

export const [useDealerContext, DealerContextProvider] = createGenericContext<DealerContextType>();

type Props = {
    children: ReactNode;
};

export const DealerProvider = ({ children }: Props) => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const { data: session } = useSession() as { data: Session | null };
    const { handleError } = useHandleError<UpdateDealerDto>();
    const { t } = useTranslation('common');
    const dealerId = useMemo(() => session?.user.id, [session]);
    const isDealerActor = useMemo(() => session?.user.actorType === ActorType.Dealer, [session]);
    const isDealerEnabled = useMemo(() => !!dealerId && isDealerActor, [dealerId, isDealerActor]);

    const {
        stores,
        storesLoading,
        refetchStores,
        selectStore: selectDealerStore,
        activeStoreId,
        setActiveStoreId,
    } = useStores(
        ActorType.Dealer,
        () =>
            api.dealers.getDealerPaginatedStores(dealerId ?? '', {
                page: 1,
                order: QueryOrder.ASC,
            }),
        isDealerEnabled,
        dealerId,
    );

    const { data: activeStore } = useQuery(
        ['activeStore', activeStoreId],
        () => api.dealers.getDealerStore(dealerId ?? '', activeStoreId ?? ''),
        {
            enabled: !!activeStoreId && isDealerEnabled && stores.length > 0,
            onError: () => {
                setActiveStoreId(null);
            },
            keepPreviousData: true,
        },
    );

    const { data: dealer, isLoading: dealerLoading } = useQuery(
        ['me'],
        () => api.dealers.getAuthenticatedDealer(),
        {
            enabled: isDealerEnabled,
        },
    );

    const { mutate: updateDealer } = useMutation(
        async (updateDealerDto: UpdateDealerDto) => {
            const data = await api.dealers.updateDealerInfos(dealer?.id ?? '', updateDealerDto);
            queryClient.resetQueries(['user']);
            return data;
        },
        {
            onSuccess: () => {
                toast({ title: t('feedback_saved_success') });
                queryClient.invalidateQueries(['me']);
            },
            onError: (error: AxiosError<ErrorsDto>) => {
                handleError(error);
            },
        },
    );

    const loading = useMemo(
        () =>
            isDealerEnabled &&
            (!dealer || storesLoading || dealerLoading || (!activeStore && stores.length > 0)),
        [dealer, storesLoading, dealerLoading, activeStore, stores.length, isDealerEnabled],
    );

    const dealerRole = useMemo(
        () => activeStore?.dealerRole as DealerRole,
        [activeStore?.dealerRole],
    );

    const hasDealerAccessTo = useCallback(
        (item: Feature | Section, specificCondition?: boolean) =>
            isDealerActor && dealerHasAccessTo(dealerRole, item, specificCondition),
        [dealerRole, isDealerActor],
    );

    return (
        <DealerContextProvider
            value={{
                isDealerActor,
                refetchStores,
                dealer,
                stores,
                loading,
                activeStore,
                setActiveStoreId,
                selectDealerStore,
                dealerRole,
                hasDealerAccessTo,
                updateDealer,
            }}
        >
            {children}
        </DealerContextProvider>
    );
};
