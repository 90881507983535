import CustomersCountCard from '@/components/dashboard/CustomersCountCard';
import { PremiumCard } from '@/components/dashboard/PremiumCard';
import SubscriptionsCountCard from '@/components/dashboard/SubscriptionsCountCard';
import AuthGuard from '@/components/guards/AuthGuard';
import StoresGuard from '@/components/guards/StoresGuard';
import ShellLayout from '@/components/layouts/ShellLayout';
import PageTitle from '@/components/ui/PageTitle';
import { useUserContext } from '@/contexts/user-context';
import { Section } from '@/utils/access/user-access';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import type { ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelector, SelectableDates } from '@/components/ui/charts/DateSelector';
import { subMonths } from 'date-fns';
import SalesList from '@/components/dashboard/SalesList';
import { useQuery } from '@tanstack/react-query';
import api from '@/core/client';
import { useForm } from 'react-hook-form';
import ProductsSalesCard from '@/components/dashboard/ProductsSalesCard';
import SelectFieldBase from '@/components/forms/formFields/SelectFieldBase';

const Dashboard = () => {
    const { t } = useTranslation(['common', 'dashboard', 'subscription', 'product']);
    const { user, hasAccessTo, activeStore } = useUserContext();
    const ref = useRef<HTMLIFrameElement>(null);

    const [selectedStartDate, setSelectedStartDate] = useState<string | undefined>(
        subMonths(new Date(), 12).toISOString(),
    );
    const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(
        new Date().toISOString(),
    );
    const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>();
    const { data: selectableStores } = useQuery({
        queryKey: ['allChildrenStores', activeStore?.id],
        queryFn: () => api.stores.getAllChildrenStores(activeStore!.id),
        enabled: !!activeStore?.id,
        select: (data) => [activeStore!, ...data],
    });

    const methods = useForm<{ selectedStoreIds?: string[] }>({
        defaultValues: {
            selectedStoreIds: undefined,
        },
    });

    useEffect(() => {
        if (selectableStores && activeStore?.id) {
            setSelectedStoreIds(selectableStores.map((store) => store.id));
        }
    }, [selectableStores, methods, activeStore?.id]);

    const handleDateChange = (startDate?: string, endDate?: string) => {
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    return (
        <>
            {user &&
            [
                '55413062-f15c-44dc-b9e9-5e655311cd8b', // catherine.demarly@floa.fr
                'b3f7fdf9-05dd-4eaf-9d95-0ae3c147ef46', // emilie.trouve@floa.fr
                '8058ee47-1973-43fd-8eb2-e199b765515b', // maxime.dujardin@floa.com
            ].includes(user.id) ? (
                <iframe
                    ref={ref}
                    title="datastudio"
                    width="100%"
                    height="600"
                    src="https://lookerstudio.google.com/embed/reporting/c6ab237c-53db-4459-9d89-9dc2be0fdcd7/page/jnW3C"
                />
            ) : user &&
              [
                  '063f16a8-f48c-4e22-b3d0-bc55e6bb6995', // s.decarvalho@campings-paradis.com
              ].includes(user.id) ? (
                <iframe
                    ref={ref}
                    title="datastudio"
                    width="100%"
                    height="600"
                    src="https://lookerstudio.google.com/embed/reporting/6f7a1e03-ef08-43b8-8963-bfae7307fab0/page/jnW3C"
                />
            ) : (
                hasAccessTo(Section.DASHBOARD) && (
                    <>
                        <PageTitle
                            label={t('common:dashboard')}
                            rightContent={
                                <Flex flexWrap="wrap" gap={3} alignItems="center">
                                    <SelectFieldBase
                                        variant="plain"
                                        value={selectedStoreIds}
                                        size="sm"
                                        width={170}
                                        isMulti
                                        name="selectedStoreIds"
                                        onChange={(values) => {
                                            setSelectedStoreIds(values);
                                        }}
                                        options={(selectableStores || []).map((store) => ({
                                            label: store.name,
                                            value: store.id,
                                        }))}
                                        placeholder={t('common:stores')}
                                    />
                                    <DateSelector
                                        onChange={handleDateChange}
                                        startDate={selectedStartDate}
                                        endDate={selectedEndDate}
                                        defaultSelected={SelectableDates.LAST_12_MONTHS}
                                    />
                                </Flex>
                            }
                        />

                        <Grid
                            templateColumns={'repeat(12, 1fr)'}
                            gap={10}
                            mt={8}
                            mb={8}
                            alignItems="stretch"
                        >
                            <GridItem colSpan={{ base: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
                                <SubscriptionsCountCard
                                    activeStore={activeStore}
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    selectedStoreIds={selectedStoreIds}
                                />
                            </GridItem>
                            <GridItem colSpan={{ base: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
                                <ProductsSalesCard
                                    activeStore={activeStore}
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    selectedStoreIds={selectedStoreIds}
                                />
                            </GridItem>
                            <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 6, xl: 4 }}>
                                <CustomersCountCard
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    activeStore={activeStore}
                                    selectedStoreIds={selectedStoreIds}
                                />
                            </GridItem>
                            <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 6, xl: 12 }}>
                                <PremiumCard
                                    activeStore={activeStore}
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    selectedStoreIds={selectedStoreIds}
                                />
                            </GridItem>
                            <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                                <SalesList />
                            </GridItem>
                        </Grid>
                    </>
                )
            )}
        </>
    );
};

Dashboard.getLayout = function getLayout(page: ReactElement) {
    return (
        <AuthGuard>
            <StoresGuard>
                <ShellLayout>{page}</ShellLayout>
            </StoresGuard>
        </AuthGuard>
    );
};

export default Dashboard;
