import {
    Checkbox,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Icon,
    Box,
    Heading,
} from '@chakra-ui/react';
import type { ColumnDef, Row, ExpandedState } from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getExpandedRowModel,
} from '@tanstack/react-table';
import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '@/hooks/useToast';
import { PiEmpty } from 'react-icons/pi';
import { WithLink } from '@/hoc/withLink';

interface TableProps<TItem> {
    data?: TItem[];
    columns: ColumnDef<TItem, string>[];
    isLoading?: boolean;
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
        maxSelected?: number;
    };
    expandedRow?: FC<{ row: Row<TItem> }>;
    rowHref?: (row: TItem) => string;
}

const SelectionTable = <TItem,>({
    columns,
    data = [],
    selection,
    expandedRow,
    rowHref,
}: TableProps<TItem>) => {
    const { t } = useTranslation('common');
    const toast = useToast();
    const isExpandable = !!expandedRow;
    const isSelectable = !!selection;

    const rowSelection = useMemo(
        () =>
            selection &&
            selection.selected.reduce((acc, id) => ({ ...acc, ...{ [id]: true } }), {}),
        [selection],
    );

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: isExpandable ? getExpandedRowModel() : undefined,
        onExpandedChange: isExpandable ? setExpanded : undefined,
        getRowCanExpand: () => isExpandable,
        enableRowSelection: isSelectable,
        onStateChange: isSelectable
            ? (updater) => {
                  const maxSelected = selection.maxSelected;
                  const update =
                      typeof updater === 'function' ? updater(table.getState()) : updater;

                  const selectedRows = Object.keys(update.rowSelection);
                  if (maxSelected && selectedRows.length > maxSelected) {
                      toast({
                          title: t('max_selected', { max: maxSelected }),
                          status: 'warning',
                      });
                      const selectedKeys = selectedRows.slice(0, maxSelected);
                      selection.onChange(selectedKeys);
                  } else {
                      selection.onChange(selectedRows);
                  }
              }
            : undefined,
        getRowId: isSelectable
            ? (row, relativeIndex, parent) =>
                  parent
                      ? [parent.id, row[selection.keyProperty]].join('.')
                      : (row[selection.keyProperty] as string)
            : undefined,
        state: {
            expanded,
            rowSelection,
        },
    });

    return (
        <TableContainer>
            <ChakraTable>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {isSelectable && (
                                <Th>
                                    <Checkbox
                                        isChecked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                                    />
                                </Th>
                            )}
                            {headerGroup.headers.map((header) => (
                                <Th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Fragment key={row.id}>
                            <Tr
                                clipPath={'inset(0)'} // Hack for Safari (relative on tr does not work) https://github.com/w3c/csswg-drafts/issues/1899
                                position={'relative'}
                                _hover={{
                                    backgroundColor: 'gray.50',
                                }}
                            >
                                {isSelectable && (
                                    <Td position={'relative'}>
                                        <Checkbox
                                            isChecked={row.getIsSelected()}
                                            onChange={row.getToggleSelectedHandler()}
                                        />
                                    </Td>
                                )}
                                {row.getVisibleCells().map((cell, index) => {
                                    const triggerMainLink =
                                        cell.column.columnDef.meta?.triggerMainLink ?? true;
                                    const isFirstColumn = index === 0 && !isSelectable;
                                    const rowHrefValue = rowHref?.(row.original);
                                    const isMainClickable =
                                        isFirstColumn && rowHrefValue && triggerMainLink;
                                    return (
                                        <Td
                                            key={cell.id}
                                            position={triggerMainLink ? undefined : 'relative'}
                                        >
                                            <WithLink
                                                link={isMainClickable ? rowHrefValue : undefined}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </WithLink>
                                        </Td>
                                    );
                                })}
                            </Tr>
                            {isExpandable && row.getIsExpanded() && (
                                <Tr>
                                    <Td colSpan={row.getAllCells().length}>
                                        {expandedRow({ row })}
                                    </Td>
                                </Tr>
                            )}
                        </Fragment>
                    ))}
                    {/* Empty table */}
                    {table.getRowModel().rows.length === 0 && (
                        <Tr>
                            <Td
                                color="gray.500"
                                textAlign="center"
                                colSpan={table.getVisibleFlatColumns().length}
                                pt={8}
                            >
                                <Box
                                    p={3}
                                    bgColor={'gray.200'}
                                    width={'fit-content'}
                                    margin={'auto'}
                                    borderRadius={6}
                                >
                                    <Icon as={PiEmpty} fontSize="xl" display={'flex'} />
                                </Box>
                                <Heading
                                    flexShrink={0}
                                    fontSize={'xl'}
                                    fontWeight="bold"
                                    color={'gray.800'}
                                >
                                    {t('no_search_data_found')}
                                </Heading>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </ChakraTable>
        </TableContainer>
    );
};

export default SelectionTable;
