import type { StyleProps } from '@chakra-ui/react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import useIsMobile from '@/hooks/useIsMobile';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import { HiChevronLeft } from 'react-icons/hi';
import { useRouter } from '@/hooks/useRouter';
import { useTranslation } from 'react-i18next';

export interface PageTitleProps {
    label: string;
    description?: React.ReactNode;
    rightContent?: React.ReactNode;
    goBackHrefButton?: boolean;
    eventPopover?: React.ReactElement;
}

const PageTitle = ({
    label,
    rightContent,
    description,
    goBackHrefButton,
    eventPopover,
    ...styleProps
}: PageTitleProps & StyleProps) => {
    const isMobile = useIsMobile();
    const { back } = useRouter();
    const { t } = useTranslation(['common']);
    return (
        <Box
            mb={6}
            mx={isMobile ? -4 : -8}
            mt={isMobile ? -4 : -8}
            py={4}
            gap={2}
            px={isMobile ? 4 : 8}
            borderBottomWidth="1px"
            borderBottomColor="gray.200"
            flexWrap="wrap"
            display="flex"
            justifyContent="space-between"
            overflowX="auto"
            maxW="100vw"
            {...styleProps}
        >
            <HStack flexGrow={1} spacing={3} maxW="100%">
                {goBackHrefButton && (
                    <IconButtonV2
                        IconComponent={HiChevronLeft}
                        label={t('common:go_back')}
                        onClick={() => back()}
                        isDisabled={!goBackHrefButton}
                    />
                )}
                <Box w="100%">
                    <Heading
                        flexShrink={0}
                        size="xs"
                        sx={{ '::first-letter': { textTransform: 'capitalize' } }}
                        flexGrow={1}
                        fontWeight="bold"
                        maxW="100%"
                    >
                        {label}
                    </Heading>
                    {description && (
                        <Text mt={1} fontSize="md" color="text2">
                            {description}
                        </Text>
                    )}
                </Box>
            </HStack>

            {rightContent && rightContent}
            {eventPopover && eventPopover}
        </Box>
    );
};
export default PageTitle;
