import api from '@/core/client';
import { SubscriptionStatus } from '@/generated/typing';
import type { StoreDto } from '@/generated/typing';
import { useToken } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useMemo } from 'react';
import { HiMiniDocumentText } from 'react-icons/hi2';
import { PieChart } from '../ui/charts/pie-chart/PieChart';
import Title from '../ui/Title';
import Panel from '@/components/ui/Panel';

interface ISubscriptionsCountCardProps {
    startDate?: string;
    endDate?: string;
    activeStore?: StoreDto;
    selectedStoreIds?: string[];
}
type ChartStyle = {
    label: string;
    color: string;
};

const SubscriptionsCountCard = ({
    startDate,
    endDate,
    activeStore,
    selectedStoreIds,
}: ISubscriptionsCountCardProps) => {
    const { data: countSubscriptionsBySalesChannel } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id,
            'countSubscriptionsByDateRange',
            { startDate, endDate, selectedStoreIds },
        ],
        queryFn: () =>
            api.statistics.getSubscriptionsCountByDateRange(activeStore?.id ?? '', {
                from: startDate,
                to: endDate,
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        keepPreviousData: false,
        enabled: !!activeStore?.id && !!selectedStoreIds,
    });

    const [active, pending, voided, refunded, failed, expired, unknown] = useToken('colors', [
        'green.300',
        'gray.200',
        'orange.200',
        'purple.300',
        'red.400',
        'beige.400',
        'gray.300',
    ]);

    const chartData = useMemo(() => {
        const subscriptionLabel: Record<SubscriptionStatus, ChartStyle> = {
            [SubscriptionStatus.Active]: {
                label: t('dashboard:active_subscriptions'),
                color: active,
            },
            [SubscriptionStatus.Pending]: {
                label: t('dashboard:inactive_subscriptions'),
                color: pending,
            },
            [SubscriptionStatus.Voided]: {
                label: t('dashboard:voided_subscriptions'),
                color: voided,
            },
            [SubscriptionStatus.Refunded]: {
                label: t('dashboard:refunded_subscriptions'),
                color: refunded,
            },
            [SubscriptionStatus.Failed]: {
                label: t('dashboard:failed_subscriptions'),
                color: failed,
            },
            [SubscriptionStatus.Expired]: {
                label: t('dashboard:expired_subscriptions'),
                color: expired,
            },
        };
        return (
            countSubscriptionsBySalesChannel?.map((item) => {
                return {
                    id: item.label,
                    value: item.value,
                    ...(subscriptionLabel[item.label as SubscriptionStatus] ?? {
                        label: t('dashboard:unknown'),
                        color: unknown,
                    }),
                };
            }) || []
        );
    }, [
        countSubscriptionsBySalesChannel,
        active,
        pending,
        voided,
        refunded,
        failed,
        expired,
        unknown,
    ]);

    return (
        <Panel filled>
            <Title IconComponent={HiMiniDocumentText} title={t('dashboard:subscriptions_nb')} />
            <PieChart
                data={chartData}
                margin={{ top: 6, right: 6, bottom: 6, left: 6 }}
                height="200px"
            />
        </Panel>
    );
};

export default SubscriptionsCountCard;
