import React from 'react';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { StyleProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

const Link = (props: React.PropsWithChildren<NextLinkProps & StyleProps>) => {
    return <Box {...props} as={NextLink} />;
};

export default Link;
