import api from '@/core/client';
import type { ProviderAccountDto } from '@/generated/typing';
import { ActorType } from '@/generated/typing';
import { createGenericContext } from '@/utils/create-generic-context';
import { useQuery } from '@tanstack/react-query';
import type { Session } from '@/types/next-auth';
import { useSession } from 'next-auth/react';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { providerAccountHasAccessTo } from '@/utils/access/provider-account-access';
import type { Feature, Section } from '@/utils/access/user-access';

type ProviderAccountContextType = {
    isProviderAccountActor: boolean;
    providerAccountLoading: boolean;
    providerAccount?: ProviderAccountDto;
    hasProviderAccountAccessTo: (item: Feature | Section, specificCondition?: boolean) => boolean;
};

export const [useProviderAccountContext, ProviderAccountContextProvider] =
    createGenericContext<ProviderAccountContextType>();

type Props = {
    children: ReactNode;
};

export const ProviderAccountProvider = ({ children }: Props) => {
    const { data: session } = useSession() as { data: Session | null };
    const serviceAccountId = useMemo(() => session?.user.id, [session]);
    const isProviderAccountActor = session?.user.actorType === ActorType.Provider;
    const isServiceAccountEnabled = !!serviceAccountId && isProviderAccountActor;

    const { data: providerAccount, isInitialLoading: providerAccountLoading } = useQuery(
        ['me'],
        () => api.providerAccounts.getAuthenticatedProviderAccount(),
        {
            enabled: isServiceAccountEnabled,
        },
    );

    const hasProviderAccountAccessTo = useCallback(
        (item: Feature | Section, specificCondition?: boolean) =>
            isProviderAccountActor && providerAccountHasAccessTo(item, specificCondition),
        [isProviderAccountActor],
    );

    return (
        <ProviderAccountContextProvider
            value={{
                isProviderAccountActor,
                providerAccount,
                providerAccountLoading: isProviderAccountActor && providerAccountLoading,
                hasProviderAccountAccessTo,
            }}
        >
            {children}
        </ProviderAccountContextProvider>
    );
};
