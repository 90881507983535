import type { CardProps, TabsProps } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import colors from '@/chakra/colors';
import fonts from '@/chakra/fonts';
import styles from '@/chakra/styles';
import components from '@/chakra/components';

const theme = extendTheme({
    colors,
    fonts,
    styles,
    semanticTokens: {
        colors: {
            text1: 'gray.800',
            text2: 'gray.600',
            text3: 'gray.500',
            text4: 'gray.400',
            'chakra-body-text': 'text1',
            'chakra-subtle-text': 'text2',
            'chakra-placeholder-color': 'text3',
        },
        space: {
            'space-xl': 8,
            'space-lg': 6,
            'space-md': 4,
            'space-sm': 2,
            'space-xs': 1,
        },
    },
    sizes: {
        10: '36px',
    },
    radii: {
        xs: '2px',
        sm: '4px',
        md: '6px',
    },
    fontSizes: {
        xs: '10px',
        sm: '12px',
        md: '14px',
        lg: '16px',
        xl: '18px',
    },
    fontWeights: {
        bold: 700,
        medium: 500,
        semibold: 500,
        normal: 400,
        thin: 400,
    },
    shadows: {
        base: '0 0 0 1px rgba(0, 0, 0, 0.08)',
        md: '0 0 0 1px rgba(0, 0, 0, 0.08)',
    },
    components: {
        ...components,
        Accordion: {
            baseStyle: {
                icon: {
                    color: 'text3',
                },
                button: {
                    // borderRadius: 'full',
                    // fontWeight: 'medium',
                    _hover: {
                        bg: 'gray.100',
                    },
                    fontSize: 'md',
                    fontWeight: 'medium',
                    gap: 2,
                    px: 4,
                    h: '54px',
                },
                container: {
                    overflow: 'hidden',
                    mb: 2,
                    bg: 'gray.50',
                    borderRadius: 'md',
                    borderWidth: '0',
                    border: 'none',
                },
                panel: {
                    mx: 1,
                    p: 6,
                    my: 1,
                    bg: 'white',
                    borderRadius: 'md',
                },
            },
        },
        Alert: {
            baseStyle: {
                container: {
                    borderRadius: 'md',
                },
            },
        },
        Avatar: {
            baseStyle: {
                excessLabel: {
                    bg: 'gray.50',
                    border: '1px',
                    borderColor: 'white',
                    color: 'gray.500',
                },
                container: {
                    borderRadius: 'md',
                },
            },
        },
        Card: {
            baseStyle: {
                container: {
                    // borderWidth: '2px',
                    // borderColor: 'gray.100',
                    boxShadow: 'none',
                    bg: 'white',
                },
                body: {
                    p: 0,
                },
            },
            variants: {
                filled: ({ colorScheme }: CardProps) => ({
                    container: {
                        bg: `${colorScheme}.50`,
                        color: `${colorScheme}.text`,
                    },
                }),
            },
        },
        Checkbox: {
            defaultProps: {
                colorScheme: 'primary',
            },
            baseStyle: {
                control: {
                    bg: 'white',
                    borderColor: 'gray.300',
                    borderWidth: '2px',
                    _checked: {
                        outline: 'none',
                        bg: 'primary.500',
                        border: 'solid 1px',
                        borderColor: 'primary.500',
                        _hover: {
                            bg: 'primary.500',
                            borderColor: 'primary.500',
                        },
                    },
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: 'gray.100',
                borderWidth: '1px',
            },
        },
        IconButton: {
            baseStyle: {
                borderRadius: 'md',
            },
        },
        Menu: {
            baseStyle: {
                list: {
                    border: 'none',
                    boxShadow: 'md',
                    zIndex: 'popover',
                },
                item: {
                    fontWeight: 'medium',
                    bg: 'transparent',
                    _hover: {
                        bg: 'gray.50',
                    },
                },
            },
        },
        Modal: {
            baseStyle: {
                header: {
                    fontWeight: 'bold',
                },
                footer: {
                    borderTop: '1px',
                    borderColor: 'gray.200',
                    bg: 'gray.100',
                    roundedBottom: 'md',
                },
            },
        },
        Popover: {
            baseStyle: {
                content: {
                    border: 'none',
                    boxShadow: 'base',
                },
            },
        },
        Radio: {
            defaultProps: {
                colorScheme: 'primary',
            },
        },
        Tabs: {
            defaultProps: {
                colorScheme: 'primary',
            },
            variants: {
                'soft-rounded': ({ colorScheme }: TabsProps) => ({
                    tab: {
                        '&[aria-selected=true]': {
                            bg: `${colorScheme}.50`,
                            color: `${colorScheme}.text`,
                        },
                        rounded: 'md',
                    },
                }),
                line: ({ colorScheme }: TabsProps) => ({
                    tablist: {
                        borderColor: 'gray.100',
                        borderBottomWidth: '1px',
                    },
                    tab: {
                        _active: {
                            bg: `${colorScheme}.50`,
                        },
                        '&[aria-selected=true]': {
                            fontWeight: 'bold',
                            color: `${colorScheme}.text`,
                        },
                    },
                }),
            },
            baseStyle: {
                tabpanel: {
                    p: 0,
                },
            },
        },
        Toast: {},
        Tooltip: {
            defaultProps: {
                hasArrow: true,
            },
            baseStyle: {
                fontSize: 'md',
                bg: 'gray.800',
                color: 'gray.400',
                p: 4,
                rounded: 'md',
                '--popper-arrow-bg': 'var(--chakra-colors-gray-800)',
            },
        },
        Drawer: {
            baseStyle: {
                header: {
                    fontWeight: 'bold',
                },
                footer: {
                    bg: 'gray.50',
                    borderTop: 'solid 1px',
                    borderColor: 'gray.200',
                },
            },
        },
    },
});

export default theme;
