import { Box } from '@chakra-ui/react';
import { LineChart } from '../ui/charts/LineChart';
import SimpleCounter from './StatsCounter';
import { useQuery } from '@tanstack/react-query';
import api from '@/core/client';
import { useTranslation } from 'react-i18next';
import { SlicedTooltip } from '../ui/charts/tooltip/SlicedTooltip';
import type { StoreDto } from '@/generated/typing';
import Title from '../ui/Title';
import { HiPresentationChartLine } from 'react-icons/hi2';
import { useColor } from '@/chakra/themeUtils';
import Panel from '@/components/ui/Panel';
import { differenceInDays, format, subDays } from 'date-fns';
import { useMemo } from 'react';

export interface IPremiumCardProps {
    startDate?: string;
    endDate?: string;
    activeStore?: StoreDto;
    selectedStoreIds?: string[];
}

export const PremiumCard = ({
    startDate,
    endDate,
    activeStore,
    selectedStoreIds = [],
}: IPremiumCardProps) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const color = useColor('primary.400');

    const [parsedStartDate, parsedEndDate] = useMemo(
        () => [startDate && new Date(startDate), endDate && new Date(endDate)],
        [endDate, startDate],
    );

    const { data: dailyPremium } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id ?? '',
            'getDailyPremium',
            { startDate, endDate, selectedStoreIds },
        ],
        queryFn: () =>
            api.statistics.getStorePremiumByDay(activeStore!.id, {
                from: startDate,
                to: endDate,
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        enabled: !!activeStore?.id,
        keepPreviousData: true,
    });

    const { data: premium } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id ?? '',
            'getPremium',
            { startDate, endDate, selectedStoreIds },
        ],
        queryFn: () =>
            api.statistics.getPremiumByStore(activeStore?.id ?? '', {
                from: startDate,
                to: endDate,
                selectedStoreIds,
            }),
        keepPreviousData: true,
        enabled: !!activeStore?.id && !!selectedStoreIds,
    });

    const { data: premiumComparisonPeriod } = useQuery({
        queryKey: ['stores', activeStore?.id ?? '', 'getPremiumLastMonth', selectedStoreIds],
        queryFn: () => {
            if (!parsedStartDate || !parsedEndDate) {
                return;
            }
            const daysDiff = differenceInDays(parsedEndDate, parsedStartDate);

            return api.statistics.getPremiumByStore(activeStore!.id, {
                from: parsedStartDate && subDays(parsedStartDate, daysDiff).toISOString(),
                to: parsedEndDate && subDays(parsedEndDate, daysDiff).toISOString(),
                selectedStoreIds: selectedStoreIds ?? [],
            });
        },
        enabled: !!(activeStore?.id && selectedStoreIds && parsedStartDate && parsedEndDate),
    });

    const lineData = useMemo(() => {
        return (
            dailyPremium?.map((item) => ({
                x: format(new Date(item.day), 'yyyy-MM-dd'),
                y: item.sum.toFixed(2),
            })) || []
        );
    }, [dailyPremium]);

    return (
        <>
            <Panel filled>
                <Title
                    title={t('dashboard:insurance_premium')}
                    IconComponent={HiPresentationChartLine}
                />
                <SimpleCounter
                    countTo={premium ?? 0}
                    countFrom={premiumComparisonPeriod ?? 0}
                    currency="€"
                />
                <Box style={{ height: '120px', width: '100%' }}>
                    <LineChart
                        data={[
                            {
                                id: selectedStoreIds?.join(',') + endDate + startDate,
                                label: t('dashboard:insurance_premium'),
                                data: lineData,
                                color: color,
                            },
                        ]}
                        margin={{ top: 0, right: 0, bottom: 5, left: 0 }}
                        tooltip={SlicedTooltip}
                    />
                </Box>
            </Panel>
        </>
    );
};
