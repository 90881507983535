import type { AccessMap, Feature } from './user-access';
import { Section } from './user-access';

const providerAccountAccess: AccessMap = {
    [Section.PROVIDER_SUBSCRIPTIONS]: true,
    [Section.SUBSCRIPTION_PROCEDURES]: true,
};

export const providerAccountHasAccessTo = (
    item: Section | Feature,
    specificCondition: boolean = true,
): boolean => {
    if (specificCondition !== undefined && !specificCondition) {
        return false;
    }

    return !!providerAccountAccess[item];
};
